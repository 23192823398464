@import '/styles/variables.scss';

.loginForm {
    margin-top: 30px;

    .loginInput {
        width: 100%;

        &:not(:first-child) {
            margin-top: 40px;
        }
    }
}

.additionalActions {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
}

.checkboxLabel {
    font-family: 'Inter', sans-serif;
    color: #717171;
    font-size: 1.6rem;
    line-height: 2rem;
}

.forgotPassword {
    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    font-size: 2rem;
    color: #111010;

    @media screen and (max-width: 500px) {
        font-size: 1.2rem;
    }

    a {
        color: #111010;
    }
}

.loginBtn {
    width: 355px;
    max-width: 100%;
    margin-top: 60px;
}

.signup {
    margin-top: 22px;
}

@media screen and (max-width: $bp-md) {
    .loginBtn {
        width: 100%;
    }
}
