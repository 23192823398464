@import '/styles/variables.scss';

.authLayout {
    display: flex;
    min-height: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
}

.leftContainer {
    flex-grow: 0.9;
    width: 712px;
    background-color: #141933;
    padding-right: 4%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    position: relative;
}

.rightContainer {
    flex-grow: 1.1;
    background-color: #f6f6f9;
    display: flex;
    justify-content: center;
    position: relative;
    width: 888px;
    max-width: 100%;
}

.rightInner {
    padding-top: 58px;
    padding-left: 55px;
    padding-right: 52px;
    padding-bottom: 60px;
    background: #fefdff;
    border-radius: 0px 0px 20px 20px;
    max-width: 100%;
    max-height: 95%;
    width: 722px;
}

.rightInnerHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.headerNotice {
    font-family: 'Inter', sans-serif -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.6rem;
    color: #6d707a;
    display: inline-block;
}

.form {
    margin-top: 82px;
    width: 520px;
    max-width: 100%;
}

.leftContainerContent {
    z-index: 1;
    margin-left: 20px;
}

.authTopDecoration {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.authTopDecoration2 {
    position: absolute;
    top: 20vh;
    right: 20%;
    z-index: 0;
}

.sliderSlot {
    padding-top: 103px;
    width: 100%;
    padding-bottom: 90px;
    padding-left: 5.5vw;
}

@media screen and (min-width: ($bp-lg + 1)) and (max-width: $bp-design-goal) {
    .leftContainer {
        width: 44.5%;
    }
    .rightContainer {
        width: 55.5%;
    }
}

@media screen and (max-width: $bp-lg) {
    .leftContainer {
        display: none;
    }

    .rightContainer {
        width: 100%;
    }
}

@media screen and (max-width: $bp-md) {
    .rightInner {
        min-height: 100%;
        padding: 45px;
    }

    .headerNotice {
        display: none;
    }

    .form {
        margin-top: 54px;
    }
}
