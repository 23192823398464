.sliderContainer {
    width: 403px;
    max-width: 100%;
    position: relative;
}

.slide {
    width: 100%;
    min-height: 190px;
    padding-bottom: 34px;
}

.slideTitle {
    color: #ffffff;
    font-weight: 600;
    font-family: 'Epilogue', sans-serif;
    font-size: 2.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
}

.slideSubtitle {
    color: #ffffff;
    opacity: 0.6;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
}

.slideContent {
    cursor: pointer;
    font-family: 'Epilogue', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-size: 1.5rem;
    color: #ffffff;
    margin-top: 22px;
}

.customBullet {
    width: 20px;
    height: 5px;
    background: #ffffff;
    opacity: 0.15;
    border-radius: 10px;
    transition: 0.3s all linear;
}

.customBulletActive {
    width: 40px;
    height: 5px;
    /* White */
    opacity: 100%;
    background: #ffffff;
    border-radius: 10px;
}

.customPagination {
    bottom: 10px;
    left: 0;
    width: 100%;
    position: absolute;
    text-align: left;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
}
