.mainHeading,
.subHeading {
    font-family: 'Inter', Sans-serif;
    color: #101525;
}

.mainHeading {
    font-size: 34px;
    font-weight: 500;
    line-height: 48px;

    @media screen and (max-width: 500px) {
        font-size: 20px;
    }
}

.subHeading {
    font-family: 'Inter', sans-serif;
    color: #000000;
    opacity: 0.7;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;

    @media screen and (max-width: 500px) {
        font-size: 14px;
    }
}

.input {
    width: 100%;
    margin-top: 20px;
}

.questions {
    color: #717171;
    font-size: 1.6rem;
    margin-top: 20px;

    @media screen and (max-width: 500px) {
        font-size: 14px;
    }

    a,
    .MuiLink-root .MuiLink {
        text-decoration: none;
        font-weight: 500;
        color: #4285de;
    }
}
